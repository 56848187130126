<template>
    <i v-if="!colorful" :class="`icon mentorfont ${icon}`" :style="mergeStyles"></i>
    <svg v-else class="icon" :style="mergeStyles">
        <use :xlink:href="'#' + icon"></use>
    </svg>
</template>

<script setup lang="ts">
const props = defineProps({
    icon: {
        type: String,
        required: true,
    },
    size: [String, Number],
    width: [String, Number],
    /** 是否为彩色的 */
    colorful: Boolean,
});

const mergeStyles = computed(() => {
    let res = "";
    if (props.size) {
        if (props.colorful) {
            res = `width: ${props.size}rem;height: ${props.size}rem;`;
        } else {
            res = `font-size: ${props.size}rem;`;
        }
    }
    if (props.width) {
        res += `width: ${props.width}rem;height: ${props.width}rem;`;
    }
    return res;
});
</script>
